/**
 * cm-claw.js
 * @author ConnectMedia Sweden AB - www.connectmedia.se
 * @description Information bar for the Cookie Law and
 *              GDPR Cookie Policy.
 */
var cmClaw = {
    // settings
    settings: {
        prefix: 'cm-claw',
        cookieName: 'cm-claw',
        daysToExpire: 365,
        timeout: 500
    },

    // options
    options: {
        message: '',
        linkUrl: '',
        linkText: '',
        btnText: ''
    },

    // init
    init: function(options) {
        cmClaw.options.message = options.message;
        cmClaw.options.linkUrl = options.linkUrl;
        cmClaw.options.linkText = options.linkText;
        cmClaw.options.btnText = options.btnText;

        if (cmClaw.getCookie() === undefined) {
            cmClaw.renderBar();
        }
    },

    // register the events for the buttons etc.
    registerEvents: function() {
        document.querySelector('#js-cm-claw-close').onclick = function(e) {
            cmClaw.setCookie('ok');
            cmClaw.hideBar();

            e.preventDefault();
        }
    },

    // set the cookie
    setCookie: function(val) {
        var date = new Date();
        date.setTime(date.getTime() + (cmClaw.settings.daysToExpire * 24 * 60 * 60 * 1000));

        document.cookie = cmClaw.settings.cookieName + '=' + val + '; expires=' + date.toGMTString() + '; path=/;';
    },

    // get the cookie
    getCookie: function() {
        var cookies = document.cookie.split(';')
        var cookie = undefined;

        for (var i = 0; i < cookies.length; i++) {
            var c = cookies[i].trim();
            if (c.indexOf(cmClaw.settings.cookieName) === 0) {
                cookie = c.split('=')[1];
            }
        }

        return cookie;
    },

    // create the bar and append it to the body element
    renderBar: function() {
        document.querySelector('body').insertAdjacentHTML('afterbegin', [
            '<div class="cm-claw" id="js-cm-claw">',
                '<div class="cm-claw__message">',
                    '<p>',
                        cmClaw.options.message,
                        '<a href="' + cmClaw.options.linkUrl + '">' + cmClaw.options.linkText + '</a>',
                    '</p>',
                '</div>',
                '<div class="cm-claw__close">',
                    '<a id="js-cm-claw-close" href="#">' + cmClaw.options.btnText + '</a>',
                '</div>',
            '</div>'
        ].join('\n'));

        setTimeout(function(){
            document.documentElement.classList.add(cmClaw.settings.prefix+'-loaded');
        }, cmClaw.settings.timeout);

        cmClaw.registerEvents();
    },

    // hide the bar
    hideBar: function() {
        document.documentElement.classList.remove(cmClaw.settings.prefix+'-loaded');
    }
}