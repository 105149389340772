$(function() {
    var zt = new ZingTouch.Region(document.body);
    var myElement = document.querySelectorAll('.main')[0];
    var tapEvent = new ZingTouch.Tap({
        maxDelay: 200,
        numInputs: 3,
        tolerance: 125
    })

    zt.bind(myElement, tapEvent, function(e) {
        $('.main h1').html('God Jul! Eller kanske HIPP, HIPP, HURRA!?');
    });

    /**********************************************
     * Cookie info notice
     **********************************************/
    /*cmClaw.init({
        message: 'Vi använder cookies för att förbättra upplevelsen och anpassa vår marknadsföring. Genom att surfa vidare godkänner du detta.',
        linkUrl: '/cookies/',
        linkText: 'Läs mer',
        btnText: 'Jag godkänner'
    });*/


    /**********************************************
     * Waypoints
     **********************************************/
    var waypoint = new Waypoint({
        element: $('body'),
        handler: function() {
            $('body').toggleClass('-scrolled');
        },
        offset: -1
    });


    /**********************************************
     * MagnificPopups
     **********************************************/
    // gallery
    $('.gallery--mfp').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: true,
        image: {
            cursor: null,
            titleSrc: 'data-caption'
        },
        tLoading: 'Laddar...',
        gallery: {
            enabled: true,
            preload: [0,2],
            navigateByImgClick: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tPrev: 'Föregående (Vänster piltangent)',
            tNext: 'Nästa (Höger piltangent)',
            tCounter: '%curr% av %total%'
        }
    });
});